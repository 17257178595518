
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
//services
import GlobalServices from "@/services/GlobalServices";
import TransactionServices from "@/services/TransactionServices";
//models
export default defineComponent({
  props:["settingBlockInfo"],

  components:{
    Loading,
  },

  data(){
    const toast = useToast();
    let trx:any = {
      max_net_usage_words: 0,
      max_cpu_usage_ms: 0,
      delay_sec: 0,
      context_free_actions:[],
      transaction_extensions:[],
      actions:[],
      expiration:'',
      ref_block_num: '',
      ref_block_prefix: ''
    }
    return{
      loading:true,
      toast,
      trx,
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      try{
        let date = new Date()
        date.setDate(date.getDate() + 7)
        var newDate  = date.toISOString().split(".")[0] + ".000"
        this.trx.expiration = newDate
        this.trx.ref_block_num = this.settingBlockInfo.ref_block_num
        this.trx.ref_block_prefix = this.settingBlockInfo.ref_block_prefix
      }catch(e){}
      this.loading=false;
    },

    

  },

  mounted(){
    this.init()
  },

  watch:{
    trx:{
      handler(){
        this.$emit("setSetting" , this.trx)
      },
      deep:true
    },
  }
    
})
