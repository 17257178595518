
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
import Authorization from "@/pages/proposal/components/Authorization.vue" 
//services
//models
export default defineComponent({
  props:["selectedAccount" , "actions" , "proposalName"],

  components:{
    Loading,
    Authorization
  },

  data(){
    const toast = useToast();
    let authorizations:any[] = []
    let name:string = this.proposalName
    return{
      loading:true,
      toast,
      name,
      proposer:this.selectedAccount.accountName,
      authorizations,
      counter:0
    }
  },

  computed:{
    checkName(){
      if(this.name.length>12){
        return "Proposal name must be less than 12 characters!"
      }
      if(
        this.name.includes("6") ||
        this.name.includes("7") ||
        this.name.includes("8") ||
        this.name.includes("9")
      ){
        return "You are using unacceptable characters!"
      }
      else return
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      try{
        this.setApprovalsAccounts()
      }catch(e){}
      this.loading=false;
    },

    setApprovalsAccounts(){
      let auths:any[] = []
      for(let action of this.actions){
        auths.push(action.authorization)
      }      
      this.authorizations = Array.from(auths.reduce((m, t) => m.set(t.name, t), new Map()).values())      
      this.authorizations = this.authorizations[0]
    },

    setPersonalInfo(){
      if(this.name.length>12){
        return
      }
      let data:any = {
        proposalName: this.name,
        requested:this.authorizations
      }
      this.$emit("setPersonalInfo" , data)
    },

    addCounter(){
      this.counter++
    }

  },

  mounted(){
    this.init()
  },

  watch:{
    authorizations:{
      handler(){
        this.setPersonalInfo()
      },
      deep:true
    },
    name(){
      this.setPersonalInfo()
    }
  }
    
})
