export default class AbiModel
{
    constructor(data:any=null)
    {
        if(!data)return
        var struct:any={}

        for(var a of data.structs)
        {
            struct[a.name]=a;
        }
        for(var a of data.tables)
        {
            this.tables.push(new AbiTables(a,struct[a.type]))
        }
        for(var a of data.actions)
        {
            this.actions.push(new AbiAction(a,struct[a.type]))
        }
    }
    tables:AbiTables[]=[];
    actions:AbiAction[]=[];
}

export class AbiTables
{
    constructor(data:any=null,struct:any=null)
    {
        if(!data || !struct)return;
        this.name=data?.name;
        for(var a of struct?.fields)
        {
            this.fields.push(new Field(a));
        }
        this.key=struct?.fields[0].name;
    }
    name:string='';
    key:string='';
    fields:Field[]=[];
}
export class AbiAction
{
    constructor(data:any=null,struct:any=null)
    {
        if(!data || !struct)return;
        this.name=data?.name;
        for(var a of struct?.fields)
        {
            this.fields.push(new Field(a));
        } 
    }
    name:string=''; 
    fields:Field[]=[];
}
export class Field
{
    constructor(data:any=null)
    {
        if(!data)return;
        Object.assign(this,data);
    }
    name?:string;
    type?:string;
}