
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Authorization from "@/pages/proposal/components/Authorization.vue" 
import Loading from "@/components/loading/Loading.vue"
//services
import MultisigsServices from "@/services/MultisigsServices";
import AccountService from "@/services/AccountServices";
//models
import AbiModel, { AbiAction } from "@/models/AbiMode";

export default defineComponent({
  props:["index" , "action"],

  components:{
    Loading,
    Authorization
  },

  data(){
    const toast = useToast();
    let dataLoading:boolean = false

    let act:any = JSON.parse(JSON.stringify(this.action))

    return{
      loading:true,
      toast,
      counter:0,
      dataLoading,
      act
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      this.loading=false;
    },

    addAction(action , index){
      this.$emit("addAction" , {action , index})
    },

    removeAction(index){
      this.$emit("removeAction" , index)
    },

    async refreshData(){
      this.dataLoading = true
      try {
        let t:any = await AccountService.getAbi(this.act.account)
        if(t.abi){
          let abiModel:AbiModel = new AbiModel(t.abi)
          let selectedAction:any = abiModel.actions.find((act:AbiAction) => act.name == this.act.name) 
          this.act.data = {}
          for(let field of selectedAction.fields){
            this.act.data[field.name] = null
          }
        }
      }catch(error) {}
      this.dataLoading = false
    },

    addCounter(){
      this.counter++
    }

  },

  mounted(){
    this.init()
  },

  watch:{
    act:{
      handler(){
        this.$emit("setAction" , {action:this.act , index:this.index})
      },
      deep:true
    }
  }

    
})
