import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-none" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "fs-3 fw-bold ms-1" }
const _hoisted_4 = { class: "svg-icon svg-icon-3" }
const _hoisted_5 = { class: "col-5" }
const _hoisted_6 = { class: "my-3" }
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = { class: "col-5" }
const _hoisted_9 = { class: "my-3" }
const _hoisted_10 = ["onUpdate:modelValue"]
const _hoisted_11 = { class: "col-2" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.counter), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.title) + ":", 1),
      _createElementVNode("button", {
        class: "btn btn-icon badge-light-success btn-color-success btn-sm me-1 ms-3",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.add()))
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr075.svg" })
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorizations, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row d-flex align-items-center mw-500px",
        key: index
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": ($event: any) => ((item.actor) = $event),
              class: "form-control form-control-solid",
              name: "actor",
              placeholder: "Actor"
            }, null, 8, _hoisted_7), [
              [_vModelText, item.actor]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": ($event: any) => ((item.permission) = $event),
              class: "form-control form-control-solid",
              name: "permission",
              placeholder: "Permission"
            }, null, 8, _hoisted_10), [
              [_vModelText, item.permission]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            class: "btn btn-icon badge-light-danger btn-color-danger btn-sm me-1",
            onClick: ($event: any) => (_ctx.remove(index))
          }, [
            _createElementVNode("span", _hoisted_13, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
            ])
          ], 8, _hoisted_12)
        ])
      ]))
    }), 128))
  ]))
}