
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
//components
import Loading from '@/components/loading/Loading.vue'
//services
//models
export default defineComponent({
  props:["actions"],
  components:{
    Loading,
  },

  data(){
    const toast = useToast();
    return{
      loading:true,
      toast,
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      this.loading=false;
    },

  },

  mounted(){
    this.init()
  },

    
})
