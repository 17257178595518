
import { defineComponent } from "vue";
import AccountService from "@/services/AccountServices";

export default defineComponent({
  name: "authorization",
  props:["authorizations" , "title"],

  data(){
    return{
      counter:0
    }
  },
  
  methods:{
    add(){
      this.authorizations.push({actor: '' , permission:''})
    },

    remove(index:number){
      this.authorizations.splice(index , 1)
    }
  },


  watch:{
    authorizations:{
      handler(){
        this.counter++        
        this.$emit('addCounter')
      },
      deep:true
    }
  }


});
